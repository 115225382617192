.app-container {
  margin: 0 auto;
  max-width: 1200px; /* Adjust the max-width as per your design */
  padding: 0 0px; /* Add some padding on the sides to create space for the shadows */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Apply shadow */
  background-color: #ffffff;
  min-height: 100vh; /* Set the minimum height to 100% of the viewport height */
}

body {
  background-color: #f7f7f7;
}

a {
  color: #173fa2; /* Change to the color you desire */
}

a:hover {
  color: #1d72e1; /* Change to the hover color you desire */
}

.navbar, .footer {
  background: linear-gradient(45deg, #e2759e, #d74d82);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 0 20px;
}

.footer {
  background: linear-gradient(45deg, #e2759e, #d74d82);
  display: flex;
  justify-content: center; /* Center text and links horizontally */
  align-items: center; /* Center text and links vertically */
  height: 40px;
  padding: 0 20px;
  text-align: center; /* Ensure text alignment */
  color: white; /* Text color */
}

.footer a {
  color: #ADD8E6; /* Link color */
  text-decoration: none; /* Optional: Remove underline */
  margin-left: 10px; /* Spacing for links */
}

.navbar .navbar-menu {
  display: flex; /* Use flexbox */
  align-items: center; /* Center items vertically */
}

.navbar .navbar-menu ul {
  display: flex;
  flex-direction: row;
  align-items: center; /* Center items vertically */
}

.navbar .navbar-menu ul li {
  list-style: none;
  margin: 0 10px;
}

.navbar-logo {
  /* Keep the logo styles as they are */
  width: 20%; /* Change to percentage-based width */
  max-width: 150px; /* Optional: Add a max-width to prevent the logo from becoming too small */
  height: auto;
  position: absolute; /* Adjusted to relative */
  top: 2px; /* Adjust the top position to overlap the main area */
  left: 1px; /* Adjust the left position to overlap the main area */
  z-index: 1;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .navbar-logo {
    width: 30%; /* Adjust percentage as needed for smaller screens */
  }
}

/* Keep the styles for menu items and links as they are */
.navbar-menu-item {
  color: rgb(255, 255, 255);
}

.navbar-menu-link {
  color: rgb(255, 255, 255);
  font-size: larger;
  display: flex; /* Set display to flex */
  align-items: center; /* Center items vertically */
}

.navbar-menu-icon {
  margin-right: 0.5rem;
}

.footer {
  background-color: #0d458b;

  font-family: "Sansita", sans-serif;
  font-size: large;
}

.footer p {
  color: white; /* Adjust text color as needed */
}

.footer a {
  color: white; /* Set link color to white */
  text-decoration: none; /* Remove underline from links if desired */
}

.song-title {
  font-family: "Sansita", sans-serif;
  font-size: xx-large;
  font-weight: bolder;
  text-align: center;
}

.artist-title {
  font-family: "Sansita", sans-serif;
  color: #504d4d;
  font-size: x-large;
  text-align: center;
}

.song-link {
  color: #504d4d; /* Change to your desired link color */
}

.song-link:hover {
  color: #254fb7; /* Change to your desired hover color */
  text-decoration: underline; /* Underline the links */
}

.circle-button {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid #d6d6d6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 3px;
}

/* Additional styles for the bookmark circle */
.circle-button.bookmark-button {
  background-color: #ffffff;
}

/* Styles for when the button is bookmarked */
.bookmark-button.bookmarked {
  border: 1px solid #ff5e5e;
}

/* Additional styles for the rating circle */
.circle-button.rating-button {
  background-color: #0d458b;
  color: white;
  font-size: large;
  font-weight: bold;
}

.flex.items-center {
  justify-content: center;
  margin-top: 5px; /* Add some top margin for spacing */
}

/* .song-info-container {
  display: flex;
  margin-top: 10px;
  justify-content: center; 
  align-items: center; 
  text-align: left; 
  height: 100%;
  border: 1px solid #c5c5c5;
  background-color: #fafaff;  <-- Remove this line
  padding: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0.2, 0.2); 
  padding: 10px;
  margin-bottom: 1rem;
} 


.song-info-box {
  display: flex;
  flex-wrap: wrap;
}*/

.info-item {
  margin-right: 25px; /* Adjust spacing between items as needed */
}

.spotify-embed {
  padding-top: 1rem;
}

.scoring-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

/* Ensure the score box container is centered horizontally */
.score-box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Adjust the score box style */
.score-box {
  width: 80px; /* Adjust width for desktop */
  height: 80px; /* Adjust height for desktop */
  margin: 5px;
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
}

/* Additional styles for mobile */
@media (max-width: 768px) {
  .score-box {
    width: 40px; /* Adjust width for mobile */
    height: 40px; /* Adjust height for mobile */
    font-size: 0.8rem; /* Adjust font size for mobile */
  }
}

.scoring-label {
  margin-right: 0.5rem; /* Adjust margin if needed */
  font-size: x-large;
  font-family: "Sansita", sans-serif;
}

/* .review-text {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #c5c5c5; 
  background-color: #fafaff;
  padding: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0.2, 0.2); 
}
 */
select {
  text-transform: none;
  background-color: #f0f0f0;
}

.score-box {
  width: 2.5rem; /* Set width to create a square */
  height: 2.5rem; /* Set height to match width */
  border: 1px solid #c6c6c6;
  margin-right: 0.5rem;
  background-color: rgb(255, 255, 255);
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-size: 0.8rem;
  transition: background-color 0.3s ease-out; /* Add transition effect */
  display: flex;
  justify-content: center;
  align-items: center; /* Center content vertically */
}

.score-box.selected {
  background-color: rgb(
    255,
    255,
    255
  ); /* Set background color to transparent */
  border: 2px solid #254fb7; /* Add a solid border with the desired color */
  color: #254fb7; /* Set text color to match border color */
  border-radius: 50%; /* Ensure the border creates a circle */
  font-weight: bold;
}

.score-box:focus {
  background: none;
}

.score-box:active {
  background-color: #fff;
  color: #000;
  border-color: #c6c6c6;
}

.submit-comment-button {
  padding: 0.4rem 0.8rem;
  border: 1px solid #c6c6c6;
  background-color: white;
  margin-bottom: 0.7rem;
  cursor: pointer;
  font-size: 0.8rem;
  display: block;
}

.submit-comment-button:hover {
  border: 1px solid rgb(88, 88, 88);
}

.submit-comment-button:focus {
  outline: none; /* Remove outline on focus */
}

.submit-comment-button:active {
  transform: translateY(1px); /* Add slight vertical movement on button click */
}

.font-cherry {
  font-family: "Sansita", sans-serif;
}

.audio-link-not-available-box {
  border: 1px solid #ccc; /* Add a border around the box */
  padding: 10px; /* Add padding inside the box */
  border-radius: 8px; /* Add rounded corners to the box */
  margin-bottom: 10px; /* Add some space below the box */
  text-align: center; /* Center text horizontally */
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
}

.audio-link-not-available-box p {
  font-weight: bold; /* Make the text bold */
  font-style: italic;
  margin-bottom: 8px; /* Add some space below the text */
}

.search-buttons {
  display: flex; /* Use flexbox */
  flex-wrap: wrap; /* Allow buttons to wrap to the next line */
  justify-content: center; /* Center buttons horizontally */
  margin-top: 10px; /* Add space between text and buttons */
}

.search-buttons a {
  display: inline-block; /* Display the links as blocks */
  padding: 8px 12px; /* Add padding to the buttons */
  margin: 0 5px 5px 0; /* Add space between the buttons */
  border: 1px solid #ccc; /* Add a border to the buttons */
  border-radius: 4px; /* Add rounded corners to the buttons */
  text-decoration: none; /* Remove the default underline */
  color: #333; /* Set the text color */
  transition: background-color 0.3s; /* Add a smooth transition effect */
}

.search-buttons a:hover {
  background-color: rgb(
    89,
    186,
    255
  ); /* Change the background color on hover */
  color: white;
}

/* Hit song list*/
.table-container {
  overflow-x: auto; /* Enable horizontal scrolling */
}

.justify-center {
  margin-top: 1rem;
}

/* About page */
.about-header-text {
  max-width: 600px;
  margin: 0 auto;
  font-weight: 700;
  font-size: larger;
  text-align: center;
}

.about-main-text {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 15px;
}

/* Ricky Nelson*/
.circular-image-container {
  width: 200px; /* Adjust the width and height as needed */
  height: 200px;
  border-radius: 50%; /* Makes the container circular */
  overflow: hidden; /* Ensures the image stays within the circular container */
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.circular-image-container img {
  width: 100%; /* Ensure the image fills the container */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Maintain aspect ratio while covering the container */
}

.info-text-ricky-box {
  width: 50%;
  border: 1px solid #ccc; /* Thin border */
  background-color: #f9f9f9; /* Background color */
  padding: 10px; /* Add some padding for spacing */
  margin: 0 auto; /* Center horizontally */
}
/* end of Ricky Nelson */

.profile-headers {
  font-family: "Sansita", sans-serif;
  color: #504d4d;
  font-size: xx-large;
  text-align: center;
}

.profile-headers-smaller {
  font-family: "Sansita", sans-serif;
  color: #504d4d;
  font-size: x-large;
  text-align: center;
}

.profile-user-box {
  text-align: left;
  font-weight: 500;
}

.profile-user-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 1rem;
}

.profile-update-link {
  font-style: italic;
  color: #1d91de;
}

/* Adjusting the slider */
.slider-box input[type="range"] {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #ffffff; /* Default background color */
  border-radius: 6px;
  margin-top: 3rem;
}

/* Styling the slider thumb */
.slider-box input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: #254fb7; /* Color for the slider thumb */
  border-radius: 50%;
  cursor: pointer;
}

/* Hover effect for the slider thumb */
.slider-box input[type="range"]::-webkit-slider-thumb:hover {
  background: #e2759e; /* Adjusted color for the hovered state */
}

.text-rating-filter {
  color: #5a5a5a;
  text-align: center;
  font-size: 14px;
}

/* Styling for tabs */
.tab-container {
  display: flex;
  margin: 1rem;
  align-items: center;
  justify-content: center; /* Add this line */
}

.tab-container button {
  padding: 10px 20px;
  margin-right: 10px;
  font-size: 20px;
  cursor: pointer;
  border: 1px solid #767676; /* Specify border width, style, and color */
  border-radius: 5px;
}

.active-tab {
  color: #ffffff; /* Active tab color */
  background-color: #254fb7;
}

.inactive-tab {
  color: #999; /* Inactive tab color */
}

.history-pagination {
  display: flex;
  justify-content: center; /* Center the numbers horizontally */
  margin-top: 1rem; /* Adjust margin as needed */
}

.page-item {
  margin: 0 0.5rem; /* Adjust margin between numbers */
}

.page-link {
  background-color: #ffffff;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  margin-bottom: 1rem;
}

.page-link.active {
  background-color: #3ba9f3;
  color: #fff;
}

.comment-container {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
}

.comments-section {
  padding-top: 10px;
}

.comment-text {
  font-size: 14px;
  color: #333;
}

.comment-meta {
  font-size: 12px;
  color: #a8a8a8;
}

textarea {
  background-color: #f7f7f7;
  padding: 10px;
}

.spotify-clipboard-box {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center the content */
  align-items: center; /* Horizontally center the content */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.delete-bookmarks-button-container {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  margin: 15px; /* Adjust as needed */
}

.delete-bookmarks-button {
  background-color: #ee3f3f; /* Red color */
  color: #ffffff; /* White text */
  padding: 5px 10px; /* Padding */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Cursor style */
}

.copy-spotify-urls-button {
  background-color: #27912a;
  color: white;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.copy-spotify-urls-button:hover {
  background-color: #227727;
}

.spotify-clipboard-text {
  color: #666;
  font-size: 14px;
}

/* react-burger-menu */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 24px;
}

.bm-burger-bars {
  background: #ffffff;
}

.scrolled .bm-burger-bars {
  background: #d74d82; /* Change the color as desired */
}

.bm-burger-bars-hover {
  background: #ffffff;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-menu-wrap {
  top: 0px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #0d458b;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

.bm-item {
  display: inline-block;
  margin-bottom: 10px;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .table-auto {
    overflow-x: auto; /* Enable horizontal scrolling */
  }
}

.message-container {
  text-align: center;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  color: #2c3e50; /* Darker color for better visibility */
  background-color: #ecf0f1; /* Light background to make it stand out */
}

@media (max-width: 768px) {
  .ant-table {
    font-size: 14px; /* Adjust font size */
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 8px; /* Adjust padding */
  }
}

.ant-input-affix-wrapper {
  border-radius: 0 !important; /* Remove rounded corners */
}