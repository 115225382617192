@media (max-width: 768px) {
  .song-info-container {
    order: 2; /* Change the order of the song info container */
  }

  .spotify-embed {
    order: 1; /* Change the order of the Spotify embed */
  }
}

@media (max-width: 768px) {
  .rating-section {
    text-align: center; /* Center align the content */
    font-size: x-large;
  }
}

@media (max-width: 768px) {
  .song-title {
    font-size: x-large;
    font-weight: bolder;
    text-align: center;
    overflow: hidden;
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .artist-title {
    font-size: large;
    overflow: hidden;
    max-width: 90%;
  }
}


@media (max-width: 768px) {
  .song-info-box {
    font-size: small;
    order: 1;
  }
}


/* Media query for small screens */
@media screen and (max-width: 768px) {
  .flex-col .items-center {
    flex-direction: column;
    align-items: center;
  }

  .flex .items-center {
    flex-direction: row;
  }
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .circle-button {
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 768px) {
  .song-info-box {
    display: block; /* Display items vertically on mobile */
  }
  
  .info-item {
    display: block;
    margin-bottom: 3px; /* Add some bottom margin for spacing between items on mobile */
  }
}

@media (max-width: 768px) {
  .tab-container {
    
    align-items: flex-start; /* Align items to the start */
  }

  .tab-container button {
    margin-bottom: 10px; /* Add margin between buttons */
    font-size: smaller;
  }
}

